import React from 'react'
import { Link } from 'react-router-dom'
import CorporateSideNavLiTags from 'components/corporate/SideNavLiTags'

const SideNav = (props) => {
  const logout = () => {
    localStorage.removeItem('userInfo')
    window.location = '/'
  }

  let toRender = (
    <>
      <CorporateSideNavLiTags />
    </>
  )
  return (
    <>
      <nav
        id='sidebarMenu'
        className='col-md-3 col-lg-2 d-md-block bg-light sidebar collapse border-0 box-shadow-1 bg-white'
      >
        <div className='position-sticky pt-3 position-relative'>
          <ul className='nav flex-column mb-auto'>
            {toRender}
            <li className='nav-item'>
              <Link onClick={logout} className='nav-link text-green-2 fs-11px'>
                <div className='row'>
                  <div className='col-2 ms-3'>
                    {/* <img src="./assets/img/icons/branches.svg" alt="" width={8} className="mx-auto" /> */}
                  </div>
                  <div className='col-7 fw-700'>Logout</div>
                </div>
              </Link>
            </li>
          </ul>
        </div>

        <div className='nav-item fs-11px fw-700 position-absolute bottom-0 mb-4 ms-4'>
          <span className='text-green'>Intelli-Africa Solutions ©</span>
          <a
            href='https://tumai.to/privacy-policy'
            className='text-green-3 d-block text-decoration-none'
          >
            Privacy & Terms
          </a>
        </div>
      </nav>
    </>
  )
}

SideNav.propTypes = {}

export default SideNav
