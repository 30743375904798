import { notification } from 'antd'

function Notifications(type, title, message) {
  return notification[type]({
    message: title,
    description: message,
    className: 'custom-class',
    style: {
      top: 70,
    },
  })
}

export default Notifications
