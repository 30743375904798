import NotFound from "components/NotFound";
import CorporateBalances from "pages/corporate/Balances";
import CorporateDashboard from "pages/corporate/Dashboard";
import BulkPurchases from "pages/corporate/BulkPurchases";
import SinglePurchase from "pages/corporate/SinglePurchase";
import CorporateReports from "pages/corporate/Reports";
import CorporateTransactions from "pages/corporate/Transactions";
import Login from "pages/Login";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CORPORATE_PATHS, ROOT_PATH } from "./Paths";
import PrivateRoute from "./PrivateRoute";
import DataPurchases from "pages/corporate/DataPurchases";
const Routes = () => (
  <>
    <Router>
      <Switch>
        <Route exact path={ROOT_PATH} component={Login} />
        <PrivateRoute
          exact
          path={CORPORATE_PATHS.dashboard}
          component={CorporateDashboard}
        />
        <PrivateRoute
          exact
          path={CORPORATE_PATHS.singlePurchase}
          component={SinglePurchase}
        />
        <PrivateRoute
          exact
          path={CORPORATE_PATHS.dataPurchases}
          component={DataPurchases}
        />
        <PrivateRoute
          exact
          path={CORPORATE_PATHS.transactions}
          component={CorporateTransactions}
        />
        <PrivateRoute
          exact
          path={CORPORATE_PATHS.reports}
          component={CorporateReports}
        />
        <PrivateRoute
          exact
          path={CORPORATE_PATHS.balances}
          component={CorporateBalances}
        />
        <PrivateRoute
          exact
          path={CORPORATE_PATHS.bulkPurchases}
          component={BulkPurchases}
        />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  </>
);

export default Routes;
