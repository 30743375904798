export const ROOT_PATH = '/'

function path(root, sublink) {
  return `${root}${sublink}`
}

export const CORPORATE_PATHS = {
  // topUp: path(ROOT_PATH, 'top-up'),
  dashboard: path(ROOT_PATH, 'dashboard'),
  singlePurchase: path(ROOT_PATH, 'single-purchases'),
  dataPurchases: path(ROOT_PATH, 'single-data-purchases'),
  bulkPurchases: path(ROOT_PATH, 'bulk-purchases/:bulkPackage'),
  transactions: path(ROOT_PATH, 'transactions'),
  reports: path(ROOT_PATH, 'reports'),
  balances: path(ROOT_PATH, 'balances'),
}
