import React, { useEffect, useState } from "react";
import Header from "components/Header";
import SideNav from "components/SideNav";
import { useAxios } from "hooks";
import { getLocalStorage } from "utils";
import MaterialTable from "material-table";

const CorporateTransactions = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [
    getTransactionsResponse,
    getTransactionSstatus,
    getTransactionsHttpRequest,
  ] = useAxios("get");

  const headers = {
    headers: {
      Authorization: `Bearer ${getLocalStorage("userInfo").loginToken}`,
      "Content-Type": "application/json",
    },
    mode: "cors",
  };

  useEffect(() => {
    getTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getTransactionsResponse) {
      if (getTransactionSstatus) {
        setIsLoading(false);

        setTransactions(getTransactionsResponse.data.results.transactions);
      } else {
        setIsLoading(false);
        setTimeout(() => {}, 1000);
      }
    }
  }, [getTransactionSstatus, getTransactionsResponse]);

  const getTransactions = () => {
    setIsLoading(true);
    const url = "transactions/info/";
    getTransactionsHttpRequest(url, headers);
  };

  const transactionData = transactions.map((transaction) => {
    const dateCreated = transaction.date_created;
    const date = new Date(dateCreated).toLocaleDateString();
    return {
      vendorReference: transaction.vendor_reference,
      amount: transaction.amount,
      csvType: `${transaction.transaction_type} (${transaction.processing_type})`,
      status: JSON.stringify(transaction.status),
      dateCreated: date,
      noOfProccessed: transaction.number_of_processed,
      noOfTransactions: transaction.number_of_transactions,
      noOfFailed: transaction.number_of_failed,
      processed_csv: transaction.processed_csv,
    };
  });

  return (
    <>
      <Header />

      <div className="container-fluid">
        <div className="row">
          <SideNav />

          <main className="col-md-9 ms-sm-auto col-lg-10 p-0">
            <div className="row p-4 m-0">
              <div className="card border-0 p-0 rounded-0 box-shadow-1">
                <div className="card-header border-0 blue-gradient-bg-2 text-center">
                  <h4 className="text-white fw-700">Transactions</h4>
                </div>

                <div className="card-body px-0 pt-2 pb-4">
                  <div className="table-responsive">
                    <MaterialTable
                      columns={[
                        { title: "Date", field: "dateCreated" },
                        {
                          title: "Reference",
                          field: "vendorReference",
                        },
                        { title: "Type", field: "csvType" },
                        { title: "Proccessed", field: "noOfProccessed" },
                        { title: "Total", field: "noOfTransactions" },
                        { title: "Failed", field: "noOfFailed" },
                        { title: "Amount", field: "amount" },
                        { title: "Status", field: "status" },
                      ]}
                      isLoading={isLoading}
                      actions={[
                        {
                          icon: "save",
                          tooltip: "Save CSV",
                          onClick: (event, rowData) => {
                            window.location.href = rowData.processed_csv;
                          },
                        },
                      ]}
                      data={transactionData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default CorporateTransactions;
