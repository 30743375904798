import { getLocalStorage, TokenValidation } from "utils";

const apiVersion = "api/v1/";
const url = "https://businessdr.api.tumai.to/";
//const url = "http://192.81.211.54:9021/";
const baseUrl = url + apiVersion;

const accessToken = getLocalStorage("userInfo").loginToken;

TokenValidation(accessToken);

export default function Config() {
  const config = {
    base_url: baseUrl,
    token: accessToken,
  };
  return config;
}
