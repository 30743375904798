import React from 'react'
import Main from "./Main";
import SingleDataUpload from 'components/corporate/SingleDataUpload';

const DataPurchases = () => {
  return (
    <>
    <Main component={<SingleDataUpload />} title="Single Data Purchases"/>
  </>
  )
}

export default DataPurchases