import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getLocalStorage } from 'utils'
import { ROOT_PATH } from './Paths'

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (getLocalStorage('userInfo')) {
          return <Component {...rest} {...props} />
        } else {
          return (
            <Redirect
              to={{
                pathname: ROOT_PATH,
              }}
            />
          )
        }
      }}
    />
  )
}

export default PrivateRoute
