import { useState, useEffect } from "react";
import { Spin, Form } from "antd";

import { getLocalStorage, Notifications, validatePhoneNumber } from "utils";

import { useAxios } from "hooks";
import {FilterItems} from "utils";

const SingleUpload = () => {
  const [csvFile, setCsvFile] = useState();
  const [csvData, setCsvData] = useState(false);
  const [dataAmount, setDataAmount] = useState(0);
  const [csvTotalAmount, setCSVTotalAmount] = useState(0);
  const [cleanedData, setCleanedData] = useState([]);
  const [services, setService] = useState([]);
  const [dataArray, setDataArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [response, status, process] = useAxios("post");
  // eslint-disable-next-line no-unused-vars
  const [serviceResponse, serviceStatus, servicesRequest] = useAxios("get");

  const refreshModal = () => {
    window.location.reload();
  };
  useEffect(() => {
    if (response) {
      try {
        if (response.status === 200) {
          setIsLoading(false);
          Notifications(
            "success",
            "Success",
            `Transaction sent for proccessing. Your transaction reference is ${response.data.message.response.vendor_reference}`
          );
          setTimeout(() => {
            refreshModal();
          }, 1500);
        } else {
          setIsLoading(false);
          setTimeout(() => {}, 1000);
          Notifications("error", "Error", response.data.message.response);
        }
      } catch (error) {
        Notifications("error", "Error", "Failed to process request");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
    useEffect(() => {
      if (serviceResponse) {
        try {
          if (serviceResponse.status === 200) {
            setService(serviceResponse.data.data.SERVICES["00X"].additional_data);
          }
        } catch (error) {}
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceResponse]);

    console.log("data", dataArray);

    useEffect(() => {
      servicesRequest(
        "",
        undefined,
        undefined,
        "https://www.voucherdr.tumai.to/api/v1/services/"
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const uploadSingleData = (values) => {
    try {
      setIsLoading(true);
      const headers = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorage("userInfo").loginToken}`,
        },
        mode: "cors",
      };

      const validatedPhoneNumber = validatePhoneNumber(values.phone_number);
      if (validatedPhoneNumber) {
        var data = new FormData();
        data.append("processing_type", "single");
        data.append("currency", "ZWL");
        data.append("amount", dataAmount);
        data.append("product_id", values.product_id);
        data.append("transaction_type", "data");
        data.append("transactionType", "0010");
        data.append("phone_number", values.phone_number);
        data.append("biller_id", "ECONET_DATA");


        process("transactions/post/", data, headers);
      } else {
        Notifications("error", "Error", "Invalid phone number!!");
      }
    } catch (error) {
      setIsLoading(false);
      Notifications("error", "Error", "Failed to process request!!");
    }
  };
  const onServiceProviderChange = (event) => {
    const search = event.target.value;
    let arraySearch = [];
    const arrayLength = services.length;

    console.log("search", search);
    console.log("search", arrayLength);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arrayLength; i++) {
      arraySearch.push({
        name: services[i].name.replace("Airtime", ""),
        code: services[i].code,
        additional_data: services[i].additional_data,
      });
    }

    var searchResults = FilterItems(
      search.substring(0, search.length - 1),
      arraySearch,
      "code"
    );

    let dataArraySearch = [];
    const dataArrayLength = searchResults.length;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < dataArrayLength; i++) {
      dataArraySearch = searchResults[i].additional_data.packages;
    }

    setDataArray(dataArraySearch);
  };

  const getDataChange = (event) => {
    let selectedData = dataArray.filter(
      (data) => data.code === event.target.value
    );
    setDataAmount(selectedData[0].price);
  };

  return (
    <>
      
      <Spin
      tip="Please wait while we process your transaction....."
      spinning={isLoading}
    >
      <Form name="basic" onFinish={uploadSingleData} autoComplete="off">
          <Spin spinning={isLoading}>
            <div className="row">
              <div className="mt-4">
                <label className="text-green fw-700 px-0">
                  Select Service Provider
                </label>
                <Form.Item
                  name="transaction_type"
                  rules={[
                    {
                      required: true,
                      message: "Please select a service provider!",
                    },
                  ]}
                >
                  <select
                    onChange={onServiceProviderChange}
                    class="form-select px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0 fs-12px"
                    aria-label="Default select example"
                  >
                    <option selected value="">
                      Select Service Provider{" "}
                    </option>
                    {!!services &&
                      services.map((items) => {
                        return (
                          <option key={items.code} value={`${items.code}0`}>
                            {items.name}
                          </option>
                        );
                      })}
                  </select>
                </Form.Item>
              </div>

              <div className="mt-4">
                <label className="text-green fw-700 px-0">
                  Select Data Package
                </label>
                <Form.Item
                  name="product_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select a data package!",
                    },
                  ]}
                >
                  <select
                    onChange={getDataChange}
                    class="form-select px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0 fs-12px"
                    aria-label="Default select example"
                  >
                    <option selected value="">
                      Select Service Provider{" "}
                    </option>
                    {!!dataArray &&
                      dataArray.map((items) => {
                        return (
                          <option key={items.code} value={items.code}>
                            {items.name} - $ZWL{items.price}
                          </option>
                        );
                      })}
                  </select>
                </Form.Item>
              </div>

              <div className="col-12 col-md-12 mt-4">
                <label className="text-green-2 fw-700 px-0">Phone Number</label>
                <Form.Item
                  name="phone_number"
                  rules={[{ required: true, message: "Enter phone number" }]}
                >
                  <input
                    class="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                    type="text"
                  />
                </Form.Item>
              </div>

              <div className="text-center mt-4">
            <input
              className="btn text-uppercase blue-gradient-bg-4 rounded-0 text-white fw-800 border-0 px-5"
              type="submit"
              value="submit"
            />
          </div>
            </div>
          </Spin>
        </Form>
      </Spin>
    </>
  );
};

export default SingleUpload;
