import LoginForm from 'components/LoginForm'
import React from 'react'
import { useHistory } from 'react-router'
import { CORPORATE_PATHS } from 'routing/Paths'
import { getLocalStorage } from 'utils'

const Login = (props) => {
  const history = useHistory()
  if (getLocalStorage('userInfo')) {
    history.push({
      pathname: CORPORATE_PATHS.dashboard,
    })
  }

  return (
    <>
      <div className='auth-bg py-5' style={{ marginTop: -63 }}>
        <div className='container'>
          <div className='row my-auto'>
            <div className='d-none d-lg-inline col-lg-4' />
            <div className='col-12 col-lg-4'>
              <div className='card boder-0 rounded-0'>
                <div className='card-body border-0 py-5'>
                  <LoginForm />
                </div>
              </div>
            </div>
            <div className='d-none d-lg-inline col-lg-4' />
          </div>
        </div>
      </div>
    </>
  )
}

Login.propTypes = {}

export default Login
