import React from "react";
import BulkUpload from "components/corporate/BulkUpload";
import Main from "./Main";
import { useParams } from "react-router-dom";

const BulkPurchases = () => {
  const { bulkPackage } = useParams();
  return (
    <>
      <Main
        component={<BulkUpload />}
        title={`${bulkPackage.toUpperCase()} BULK PURCHASES`}
      />
    </>
  );
};

export default BulkPurchases;
