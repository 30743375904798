import { useEffect, useState } from "react";
import { Spin, Form, Modal } from "antd";
import MaterialTable from "material-table";
import TemplateCSV from "assets/templates/voucherTemplate.csv";

import {
  getLocalStorage,
  Notifications,
  removeNullArray,
  validateCSVHeaders,
  findEmpty,
} from "utils";
import { useAxios } from "hooks";

const DataPackage = () => {
  const [csvFile, setCsvFile] = useState();
  const [csvData, setCsvData] = useState(false);
  const [csvTotalAmount, setCSVTotalAmount] = useState(0);
  const [cleanedData, setCleanedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [response, status, process] = useAxios("post");

  const [form] = Form.useForm();
  const refreshModal = () => {
    window.location.reload();
  };
  useEffect(() => {
    if (response) {
      try {
        if (response.status === 200) {
          setIsLoading(false);
          setIsModalVisible(false);
          Notifications(
            "success",
            "Success",
            `Transaction sent for proccessing. Your transaction reference is ${response.data.message.response.vendor_reference}`
          );
          setTimeout(() => {
            refreshModal();
          }, 1500);
        } else {
          let errors;
          if (response.data.message.response) {
            errors = response.data.message.response;
          } else {
            errors = "Failed to process your request";
          }
          setIsLoading(false);
          setTimeout(() => {}, 1000);
          Notifications("error", "Error", errors);
        }
      } catch (error) {
        Notifications("error", "Error", "Failed to process request");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const processCSV = (str, values, delimiter = ",") => {
    const headerRow = str.slice().split("\n");
    let amountArray = [];
    if (validateCSVHeaders(headerRow)) {
      let rows = str.slice(str.indexOf("\n") + 0).split("\n");

      const cleanedRow = removeNullArray(rows, "");

      let cleanedArray = [];
      const csvPayload = cleanedRow.map((row) => {
        const csvArray = row.split(delimiter);
        if (findEmpty(csvArray)) {
          const amount = parseFloat(csvArray[1].trim());
          amountArray.push(amount);
          cleanedArray.push({
            phone_number: csvArray[0],
            amount: amount,
            receiver_firstname: csvArray[2],
            receiver_lastname: csvArray[3],
            receiver_nationalid: csvArray[4],
          });
          return csvArray;
        }
        return null;
      });

      setCleanedData(cleanedArray);

      if (!csvPayload.includes(null)) {
        amountArray = removeNullArray(amountArray, undefined);
        const totalAmount = amountArray.reduce(
          (partialSum, a) => partialSum + a,
          0
        );

        setCSVTotalAmount(totalAmount);

        let csvContent =
          "phone_number,amount,receiver_firstname,receiver_lastname,receiver_nationalid\n";
        const row = removeNullArray(csvPayload, null);
        row.forEach(function (rowArray) {
          let row = rowArray.join(",");
          csvContent += row + "\r\n";
        });

        let file = new File([csvContent], "data.csv", {
          type: "text/csv;charset=utf-8",
        });

        var data = new FormData();
        data.append("processing_type", "bulk");
        data.append("currency", "usd");
        data.append("amount", totalAmount);
        data.append("transaction_type", "Vouchers");
        data.append("biller_id", values.billerId);
        data.append("data_csv", file);

        setCsvData(data);
        setIsLoading(false);
        setIsModalVisible(true);
      } else {
        setIsLoading(false);
        setIsModalVisible(false);
        Notifications(
          "error",
          "Error",
          "Your CSV contains empty fields. Please check your CSV file"
        );
      }
    } else {
      setIsLoading(false);
      setIsModalVisible(false);
      Notifications(
        "error",
        "Error",
        "Please fix your CSV headers. Download the template for reference"
      );
    }
  };

  const cleanCsv = (values) => {
    setIsLoading(true);
    const file = csvFile;
    const reader = new FileReader();
    reader.onload = function (e) {
      const text = e.target.result;
      processCSV(text, values);
    };

    reader.readAsText(file);
  };

  const uploadData = (values) => {
    try {
      setIsLoading(true);
      const headers = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorage("userInfo").loginToken}`,
        },
        mode: "cors",
      };
      if (csvData) {
        process("transactions/post/", csvData, headers);
      }
    } catch (error) {
      setIsLoading(false);
      Notifications("error", "Error", "Failed to process request");
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
    window.location.reload();
  };

  return (
    <>
      <Modal
        style={{ marginTop: "-20px" }}
        visible={isModalVisible}
        footer={false}
        onCancel={closeModal}
        width={800}
      >
        <Spin spinning={isLoading}>
          <div class="modal-dialog modal-lg">
            <div class="modal-content border-0 rounded-0">
              <div class="modal-header blue-gradient-bg-4 border-0 rounded-0">
                <h5
                  class="modal-title text-white text-uppercase fs-16px fw-700"
                  id="add-productLabel"
                >
                  Total: $USD {csvTotalAmount.toFixed(2)}
                </h5>
              </div>
              <div class="modal-body border-0 rounded-0">
                <Spin
                  tip="Please wait while we process your request"
                  spinning={isLoading}
                >
                  <MaterialTable
                    columns={[
                      { title: "First Name", field: "receiver_firstname" },
                      { title: "Last Name", field: "receiver_lastname" },
                      { title: "National ID", field: "receiver_nationalid" },
                      { title: "Phone Number", field: "phone_number" },
                      { title: "Amount", field: "amount" },
                    ]}
                    data={cleanedData}
                  />
                  <div className="text-center mt-4">
                    <input
                      onClick={() => {
                        uploadData("bulk");
                      }}
                      className="btn text-uppercase blue-gradient-bg-4 rounded-0 text-white fw-800 border-0 px-5"
                      type="submit"
                      value="Continue"
                    />
                  </div>
                </Spin>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>

      <Spin
        tip="Please wait while we clean your file....."
        spinning={isLoading}
      >
        <Form name="basic" onFinish={cleanCsv} form={form} autoComplete="off">
          <Spin spinning={isLoading}>
            <div className="row">
              <div className="mt-4">
                <label className="text-green fw-700 px-0">Select Biller</label>
                <Form.Item
                  name="billerId"
                  rules={[
                    {
                      required: true,
                      message: "Please select a biller!",
                    },
                  ]}
                >
                  <select
                    class="form-select px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0 fs-12px"
                    aria-label="Default select example"
                  >
                    <option selected value="">
                      Select Option{" "}
                    </option>
                    <option value="NRICHARDS">N Richards</option>
                    <option value="GAIN">Gain</option>
                  </select>
                </Form.Item>
              </div>
              <div className="col-12 col-md-12 mt-4">
                <label className="text-green-2 fw-700 px-0">
                  Upload CSV File
                </label>
                <Form.Item name="csv">
                  <input
                    class="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                    type="file"
                    onChange={(e) => {
                      setCsvFile(e.target.files[0]);
                    }}
                    accept="csv"
                  />
                </Form.Item>
              </div>

              <div className="text-center mt-4">
                <input
                  className="btn text-uppercase blue-gradient-bg-4 rounded-0 text-white fw-800 border-0 px-5"
                  type="submit"
                  value="submit"
                />

                <a
                  href={TemplateCSV}
                  style={{ marginLeft: "12px" }}
                  className="btn text-uppercase orange-gradient-bg-2 rounded-0 text-white fw-800 border-0 px-5"
                >
                  Download Template
                </a>
              </div>
            </div>
          </Spin>
        </Form>
      </Spin>
    </>
  );
};

export default DataPackage;
