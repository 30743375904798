import React from "react";
import Header from "components/Header";
import { Card } from 'antd';
import SideNav from "components/SideNav";

const Main = (props) => {
const { component, title } = props
  return (
    <>
      <Header />

      <div className="container-fluid">
        <div className="row">
          <SideNav />

          <main className="col-md-9 ms-sm-auto col-lg-10 p-0">
            <div className="row p-4 m-0">
              <div className="card border-0 p-0 rounded-0 box-shadow-1">
                <div className="card-header border-0 blue-gradient-bg-2 d-flex justify-content-between px-5">
                  <h4 className="text-white fw-700">{title}</h4>
                </div>
                <div>
                  <div>
                    <Card
                      bordered={false}
                    >
                        {component}
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Main;
