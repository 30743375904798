import { SimpleRegex } from "simple-regex";

const getPhoneNumberProvider = (phoneNumber) => {
  let code;
  let name;
  let status = true;
  if (SimpleRegex.MobileNumber.Econet.test(phoneNumber)) {
    code = "001";
    name = "Econet";
  } else if (SimpleRegex.MobileNumber.Netone.test(phoneNumber)) {
    code = "002";
    name = "Netone";
  } else if (SimpleRegex.MobileNumber.Telecel.test(phoneNumber)) {
    code = "003";
    name = "Telecel";
  } else {
    code = "Not Found";
    name = "Not Found";
    status = false;
  }
  return { code: code, name: name, status: status };
};
export default getPhoneNumberProvider;

export const validatePhoneNumber = (phoneNumber) => {
  const lastChars = phoneNumber.slice(-9);
  const validatedPhoneNumber = `263${lastChars}`;
  if (validatedPhoneNumber.length === 12) {
    return validatedPhoneNumber;
  } else {
    return false;
  }
};

export const validateCSVHeaders = (array) => {
  array = array[0].split(",");
  const phone_number = array[0];
  const amount = array[1];
  const receiver_firstname = array[2];
  const receiver_lastname = array[3];
  const receiver_nationalid = array[4];

  if (phone_number === "phone_number") {
    if (amount === "amount") {
      if (receiver_firstname === "receiver_firstname") {
        if (receiver_lastname === "receiver_lastname") {
          if (receiver_nationalid === "receiver_nationalid") {
            return true;
          }
        }
      }
    }
  }
  return false;
};

export const findEmpty = (array) => {
  const phone_number = array[0];
  const amount = array[1];
  const receiver_firstname = array[2];
  const receiver_lastname = array[3];
  const receiver_nationalid = array[4];

  if (phone_number) {
    if (amount) {
      if (receiver_firstname) {
        if (receiver_lastname) {
          if (receiver_nationalid) {
            return true;
          }
        }
      }
    }
  }
  return false;
};
