import { useEffect, useState } from "react";
import { Spin, Form, Modal } from "antd";
import MaterialTable from "material-table";
import TemplateCSV from "assets/templates/airtimeTemplate.csv";

import {
  getLocalStorage,
  getPhoneNumberProvider,
  Notifications,
  removeNullArray,
  validatePhoneNumber,
} from "utils";
import { useAxios } from "hooks";

const AirtimePackage = () => {
  const [csvFile, setCsvFile] = useState();
  const [csvData, setCsvData] = useState(false);
  const [csvTotalAmount, setCSVTotalAmount] = useState(0);
  const [cleanedNumberCount, setCleanedCount] = useState(0);
  const [cleanedData, setCleanedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [response, status, process] = useAxios("post");

  const [form] = Form.useForm();
  const refreshModal = () => {
    window.location.reload();
  };
  useEffect(() => {
    if (response) {
      try {
        if (response.status === 200) {
          setIsLoading(false);
          setIsModalVisible(false);
          Notifications(
            "success",
            "Success",
            `Transaction sent for proccessing. Your transaction reference is ${response.data.message.response.vendor_reference}`
          );
          setTimeout(() => {
            refreshModal();
          }, 1500);
        } else {
          setIsLoading(false);
          setTimeout(() => {}, 1000);
          let errors;
          if (response.data.message.response) {
            errors = response.data.message.response;
          } else {
            errors = "Failed to process your request";
          }
          Notifications("error", "Error", errors);
        }
      } catch (error) {
        Notifications("error", "Error", "Failed to process request");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const processCSV = (str, values, delimiter = ",") => {
    const rows = str.slice(str.indexOf("\n") + 0).split("\n");

    let amountArray = [];
    let cleanedCount = 0;

    let cleanedArray = [];

    // let failedCleaningCount = 0;
    const csvPayload = rows.map((row) => {
      const csvArray = row.split(delimiter);
      const validatedPhoneNumber = validatePhoneNumber(csvArray[0]);
      if (validatedPhoneNumber) {
        const provider = getPhoneNumberProvider(validatedPhoneNumber);
        cleanedArray.push({
          phone_number: validatedPhoneNumber,
          amount: csvArray[1],
          provider: provider.name,
        });

        if (provider.status) {
          cleanedCount = cleanedCount + 1;
          const amount = parseFloat(csvArray[1].trim());
          amountArray.push(amount);
          const payload = [
            validatedPhoneNumber,
            csvArray[1].trim(),
            provider.code,
          ];
          return payload;
        }
      }
      return null;
    });

    setCleanedData(cleanedArray);

    amountArray = removeNullArray(amountArray, undefined);
    const totalAmount = amountArray.reduce(
      (partialSum, a) => partialSum + a,
      0
    );

    setCSVTotalAmount(totalAmount);

    let csvContent = "phone_number,amount,transaction_type\n";
    const row = removeNullArray(csvPayload, null);
    row.forEach(function (rowArray) {
      let row = rowArray.join(",");
      csvContent += row + "\r\n";
    });


    let file = new File([csvContent], "data.csv", {
      type: "text/csv;charset=utf-8",
    });

    var data = new FormData();
    data.append("processing_type", "bulk");
    data.append("currency", "zwl");
    data.append("amount", totalAmount);
    data.append("transaction_type", "Airtime");
    data.append("data_csv", file);
    setCleanedCount(cleanedCount);

    setCsvData(data);
    setIsLoading(false);
    setIsModalVisible(true);
  };


  const cleanCsv = (values) => {
    setIsLoading(true);
    const file = csvFile;
    const reader = new FileReader();
    reader.onload = function (e) {
      const text = e.target.result;
      processCSV(text, values);
    };

    reader.readAsText(file);
  };

  const uploadData = (values) => {
    try {
      setIsLoading(true);
      const headers = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorage("userInfo").loginToken}`,
        },
        mode: "cors",
      };
      if (csvData) {
        if (cleanedNumberCount > 0) {
          process("transactions/post/", csvData, headers);
        } else {
          setIsLoading(false);
          Notifications("error", "Error", "No valid phone number found");
        }
      }
    } catch (error) {
      setIsLoading(false);
      Notifications("error", "Error", "Failed to process request");
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
    window.location.reload();
  };

  return (
    <>
      <Modal
        style={{ marginTop: "-20px" }}
        visible={isModalVisible}
        footer={false}
        onCancel={closeModal}
        width={800}
      >
        <Spin spinning={isLoading}>
          <div class="modal-dialog modal-lg">
            <div class="modal-content border-0 rounded-0">
              <div class="modal-header blue-gradient-bg-4 border-0 rounded-0">
                <h5
                  class="modal-title text-white text-uppercase fs-16px fw-700"
                  id="add-productLabel"
                >
                  Total: $ZWL {csvTotalAmount.toFixed(2)}
                </h5>
              </div>
              <div class="modal-body border-0 rounded-0">
                <Spin
                  tip="Please wait while we process your request"
                  spinning={isLoading}
                >
                  <MaterialTable
                    columns={[
                      { title: "Phone Number", field: "phone_number" },
                      { title: "Amount", field: "amount" },
                      { title: "Service Provider", field: "provider" },
                    ]}
                    data={cleanedData}
                  />
                  <div className="text-center mt-4">
                    <input
                      onClick={() => {
                        uploadData("bulk");
                      }}
                      className="btn text-uppercase blue-gradient-bg-4 rounded-0 text-white fw-800 border-0 px-5"
                      type="submit"
                      value="Continue"
                    />
                  </div>
                </Spin>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>

      <Spin
        tip="Please wait while we clean your file....."
        spinning={isLoading}
      >
        <Form name="basic" onFinish={cleanCsv} form={form} autoComplete="off">
          <Spin spinning={isLoading}>
            <div className="row">
              <div className="col-12 col-md-12 mt-4">
                <label className="text-green-2 fw-700 px-0">
                  Upload CSV File
                </label>
                <Form.Item name="csv">
                  <input
                    class="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                    type="file"
                    onChange={(e) => {
                      setCsvFile(e.target.files[0]);
                    }}
                    accept="csv"
                  />
                </Form.Item>
              </div>

              <div className="text-center mt-4">
                <input
                  className="btn text-uppercase blue-gradient-bg-4 rounded-0 text-white fw-800 border-0 px-5"
                  type="submit"
                  value="submit"
                />

                <a
                  href={TemplateCSV}
                  style={{ marginLeft: "12px" }}
                  className="btn text-uppercase orange-gradient-bg-2 rounded-0 text-white fw-800 border-0 px-5"
                >
                  Download Template
                </a>
              </div>
            </div>
          </Spin>
        </Form>
      </Spin>
    </>
  );
};

export default AirtimePackage;
