/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Header from "components/Header";
import SideNav from "components/SideNav";
import { Config } from "config";
import { useAxios } from "hooks";
import { getLocalStorage } from "utils";

const Dashboard = (props) => {
  const config = Config();

  useEffect(() => {
    if (getLocalStorage("userInfo")) {
      getUserInfo();
    } else {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [statistics, setSattistics] = useState({});
  const [response, status, httpRequest] = useAxios("get");
  const headers = {
    headers: {
      Authorization: `Bearer ${getLocalStorage("userInfo").loginToken}`,
      "Content-Type": "application/json",
    },
    mode: "cors",
  };

  useEffect(() => {
    if (response) {
      if (status) {
        setSattistics(response.data.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const getUserInfo = () => {
    const url = "user/info/";
    httpRequest(url, headers);
  };

  useEffect(() => {
    if (statistics) {
      getUserInfo();
    } else {
      getUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />

      <div className="container-fluid">
        <div className="row">
          <SideNav />
          <main className="col-md-9 ms-sm-auto col-lg-10 p-0">
            <div className="row px-4 pb-4 m-0">
              <div className="col-12 col-md-6 col-lg-3 mt-4">
                <div className="card box-shadow-1 border-0 rounded-0">
                  <div className="card-body">
                    <h6 className="text-uppercase fw-800 fs-12px text-green-3">
                      Balance
                    </h6>

                    {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                    <h4 className="text-green fw-600 d-inline">
                      {statistics["balance"] || 0.0}
                    </h4>
                    <span className="text-green ms-2 fw-600">ZWL</span>

                    <a
                      href
                      className="btn btn-sm w-100 orange-gradient-bg-1 border-0 rounded-pill text-white fw-800 mt-2 fs-12px"
                    >
                      Available
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mt-4">
                <div className="card box-shadow-1 border-0 rounded-0">
                  <div className="card-body">
                    <h6 className="text-uppercase fw-800 fs-12px text-green-3">
                      Balance
                    </h6>

                    {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                    <h4 className="text-green fw-600 d-inline">
                      {statistics["balance"] || 0.0}
                    </h4>
                    <span className="text-green ms-2 fw-600">USD</span>

                    <a
                      href
                      className="btn btn-sm w-100 orange-gradient-bg-1 border-0 rounded-pill text-white fw-800 mt-2 fs-12px"
                    >
                      Available
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3 mt-4">
                <div className="card box-shadow-1 border-0 rounded-0">
                  <div className="card-body">
                    <h6 className="text-uppercase fw-800 fs-12px text-green-3">
                      Transactions
                    </h6>

                    <h4 className="text-green fw-800 d-inline">
                      {statistics["transactions"] || 0}
                    </h4>
                    <span className="text-green ms-2 fw-600">Total</span>

                    <a
                      href
                      className="btn btn-sm w-100 blue-gradient-bg-4 border-0 rounded-pill text-white fw-800 mt-2 fs-12px"
                    >
                      Total
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3 mt-4">
                <div className="card box-shadow-1 border-0 rounded-0">
                  <div className="card-body">
                    <h6 className="text-uppercase fw-800 fs-12px text-green-3">
                      Transactions
                    </h6>

                    <h4 className="text-green fw-800 d-inline">
                      {statistics["processed"] || 0}
                    </h4>
                    <span className="text-green ms-2 fw-600">Total</span>

                    <a
                      href
                      className="btn btn-sm w-100 green-gradient-bg-1 border-0 rounded-pill text-white fw-800 mt-2 fs-12px"
                    >
                      Processed
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3 mt-4">
                <div className="card box-shadow-1 border-0 rounded-0">
                  <div className="card-body">
                    <h6 className="text-uppercase fw-800 fs-12px text-green-3">
                      Transactions
                    </h6>

                    <h4 className="text-green fw-800 d-inline">
                      {statistics["failed"] || 0}
                    </h4>
                    <span className="text-green ms-2 fw-600">Total</span>

                    <a
                      href
                      className="btn btn-sm w-100 purple-gradient-bg-1 border-0 rounded-pill text-white fw-800 mt-2 fs-12px"
                    >
                      Failed
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mt-4">
                <div className="card box-shadow-1 border-0 rounded-0">
                  <div className="card-body">
                    <h6 className="text-uppercase fw-800 fs-12px text-secondary">
                      Total Amount
                    </h6>

                    <h4 className="text-green fw-800 d-inline">
                      {statistics["amount"] || 0.0}
                    </h4>
                    <span className="text-green ms-2 fw-600">Total</span>

                    <a
                      href
                      className="btn btn-sm w-100 orange-gradient-bg-1 border-0 rounded-pill text-white fw-800 mt-2 fs-12px"
                    >
                      Processed
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
