import React from "react"
import Main from "./Main";
import SingleUpload from "components/corporate/SingleUpload";

const SinglePurchases = () => {
  return (
    <>
      <Main component={<SingleUpload />} title="Single Purchases"/>
    </>
  );
};

export default SinglePurchases;
