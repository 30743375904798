import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CORPORATE_PATHS } from "routing/Paths";
import DashboardIcon from "assets/icons/dashboard.svg";
import { Modal, Spin, Form } from "antd";
import { useAxios } from "hooks";
import { getLocalStorage, Notifications } from "utils";
import { IoIosKey, IoIosList } from "react-icons/io";
import { GiPayMoney, GiTakeMyMoney } from "react-icons/gi";

const CorporateSideNavLiTags = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBulkModalVisible, setIsBulkModalIsVisible] = useState(false);
  const [isSingleModalVisible, setIsSingleModalIsVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [response, status, process] = useAxios("post");

  const history = useHistory();

  useEffect(() => {
    if (response) {
      try {
        if (response.status === 200) {
          setIsLoading(false);
          Notifications(
            "success",
            "Success",
            `Your password was changed successfully`
          );
          setIsModalVisible(false);
        } else {
          setIsLoading(false);
          setTimeout(() => {}, 1000);
          Notifications("error", "Error", response.data.info.message.error);
        }
      } catch (error) {
        Notifications("error", "Error", "Failed to process request");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setIsBulkModalIsVisible(false);
    setIsSingleModalIsVisible(false);
  };

  const onFinish = (values) => {
    setIsLoading(true);

    if (values.password === values.password2) {
      const payload = {
        old_password: values.old_password,
        new_password: values.password,
      };

      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorage("userInfo").loginToken}`,
        },
        mode: "cors",
      };

      process("password/change/", payload, headers);
    } else {
      setIsLoading(false);
      Notifications("error", "Error", "Passwords do not match");
    }
  };

  const navigateBulk = (values) => {
    setIsBulkModalIsVisible(false);
    history.push({
      pathname: `/bulk-purchases/${values.package}`,
    });
  };

  const navigateSingle = (values) => {
    setIsSingleModalIsVisible(false);
    history.push({
      pathname: `/${values.package}`,
    });
  };
  return (
    <>
      <li className="nav-item mt-5">
        <Link
          to={CORPORATE_PATHS.dashboard}
          className="nav-link text-green-2 fs-11px"
        >
          <div className="row">
            <div className="col-2 ms-3">
              <img src={DashboardIcon} alt="" width={13} className="mx-auto" />
            </div>
            <div className="col-8 fw-700">Dashboard</div>
          </div>
        </Link>
      </li>
      <hr className="blue-gradient-bg-3 opacity-75 w-100 my-1" />
      <li className="nav-item">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          onClick={() => {
            setIsSingleModalIsVisible(true);
          }}                                
          href="#"
          className="nav-link text-green-2 fs-11px"
        >
          <div className="row">
            <div className="col-2 ms-3">
              <GiTakeMyMoney size="1.9em" />
            </div>
            <div className="col-8 fw-700">Single Purchases</div>
          </div>
        </a>
      </li> 
      <hr className="blue-gradient-bg-3 opacity-75 w-100 my-1" />
       <li className="nav-item">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          onClick={() => {
            setIsBulkModalIsVisible(true);
          }}
          href="#"
          className="nav-link text-green-2 fs-11px"
        >
          <div className="row">
            <div className="col-2 ms-3">
              <GiPayMoney size="1.9em" />
            </div>
            <div className="col-8 fw-700">Bulk Purchases</div>
          </div>
        </a>
      </li> 
     
      <hr className="blue-gradient-bg-3 opacity-75 w-100 my-1" />
      <li className="nav-item">
        <Link
          to={CORPORATE_PATHS.transactions}
          className="nav-link text-green-2 fs-11px"
        >
          <div className="row">
            <div className="col-2 ms-3">
              <IoIosList size="1.9em" />
            </div>
            <div className="col-8 fw-700">Transactions</div>
          </div>
        </Link>
      </li>
      <hr className="blue-gradient-bg-3 opacity-75 w-100 my-1" />
      <li className="nav-item">
        <a onClick={openModal} href className="nav-link text-green-2 fs-11px">
          <div className="row">
            <div className="col-2 ms-3">
              <IoIosKey size="1.9em" />
            </div>
            <div className="col-8 fw-700">Update Password</div>
          </div>
        </a>
      </li>
      <hr className="blue-gradient-bg-3 opacity-75 w-100 my-1" />
      <Modal
        style={{ marginTop: "-20px" }}
        visible={isBulkModalVisible}
        footer={false}
        onCancel={closeModal}
        width={800}
      >
        <Spin spinning={isLoading}>
          <div class="modal-dialog modal-lg">
            <div class="modal-content border-0 rounded-0">
              <div class="modal-header blue-gradient-bg-4 border-0 rounded-0">
                <h5
                  class="modal-title text-white text-uppercase fs-16px fw-700"
                  id="add-productLabel"
                >
                  BULK PURCHASE OPTION
                </h5>
              </div>
              <div class="modal-body border-0 rounded-0">
                <Spin
                  tip="Please wait while we process your request"
                  spinning={isLoading}
                >
                  <Form onFinish={navigateBulk} autoComplete="off">
                    <div className="mt-4">
                      <label className="text-green fw-700 px-0">
                        Select Bulk Option
                      </label>
                      <Form.Item
                        name="package"
                        rules={[
                          {
                            required: true,
                            message: "Please select an option!",
                          },
                        ]}
                      >
                        <select
                          class="form-select px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0 fs-12px"
                          aria-label="Default select example"
                        >
                          <option selected value="">
                            Select Option{" "}
                          </option>
                          <option value="airtime">Airtime</option>
                          <option value="data">Data</option>
                          <option value="voucher">Voucher</option>
                        </select>
                      </Form.Item>
                    </div>
                    <br />

                    <div className="mt-4">
                      <input
                        className="btn text-uppercase w-100 blue-gradient-bg-1 rounded-pill text-white fw-800 border-0 px-5"
                        type="submit"
                        value="Continue"
                      />
                    </div>
                  </Form>
                </Spin>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>

      <Modal
        style={{ marginTop: "-20px" }}
        visible={isSingleModalVisible}
        footer={false}
        onCancel={closeModal}
        width={800}
      >
        <Spin spinning={isLoading}>
          <div class="modal-dialog modal-lg">
            <div class="modal-content border-0 rounded-0">
              <div class="modal-header blue-gradient-bg-4 border-0 rounded-0">
                <h5
                  class="modal-title text-white text-uppercase fs-16px fw-700"
                  id="add-productLabel"
                >
                  SINGLE PURCHASE OPTION
                </h5>
              </div>
              <div class="modal-body border-0 rounded-0">
                <Spin
                  tip="Please wait while we process your request"
                  spinning={isLoading}
                >
                  <Form onFinish={navigateSingle} autoComplete="off">
                    <div className="mt-4">
                      <label className="text-green fw-700 px-0">
                        Select Single Option
                      </label>
                      <Form.Item
                        name="package"
                        rules={[
                          {
                            required: true,
                            message: "Please select an option!",
                          },
                        ]}
                      >
                        <select
                          class="form-select px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0 fs-12px"
                          aria-label="Default select example"
                        >
                          <option selected value="">
                            Select Option{" "}
                          </option>
                          <option value="single-purchases">Airtime</option>
                          <option value="single-data-purchases">Data</option>
                        </select>
                      </Form.Item>
                    </div>
                    <br />

                    <div className="mt-4">
                      <input
                        className="btn text-uppercase w-100 blue-gradient-bg-1 rounded-pill text-white fw-800 border-0 px-5"
                        type="submit"
                        value="Continue"
                      />
                    </div>
                  </Form>
                </Spin>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>


      <Modal
        style={{ marginTop: "-20px" }}
        visible={isModalVisible}
        footer={false}
        onCancel={closeModal}
        width={800}
      >
        <Spin spinning={isLoading}>
          <div class="modal-dialog modal-lg">
            <div class="modal-content border-0 rounded-0">
              <div class="modal-header blue-gradient-bg-4 border-0 rounded-0">
                <h5
                  class="modal-title text-white text-uppercase fs-16px fw-700"
                  id="add-productLabel"
                >
                  Update Password
                </h5>
              </div>
              <div class="modal-body border-0 rounded-0">
                <Spin
                  tip="Please wait while we process your request"
                  spinning={isLoading}
                >
                  <Form onFinish={onFinish} autoComplete="off">
                    <div className="text-center">
                      <img
                        src="./assets/img/logo.svg"
                        alt=""
                        className="img-fluid"
                        width={80}
                      />
                    </div>

                    <div className="mt-4">
                      <label className="text-green fw-700 px-0">
                        Old Password
                      </label>
                      <Form.Item
                        name="old_password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your old password!",
                          },
                        ]}
                      >
                        <input
                          className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                          type="password"
                        />
                      </Form.Item>
                    </div>

                    <div className="mt-4">
                      <label className="text-green fw-700 px-0">
                        New Password
                      </label>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your pasword!",
                          },
                        ]}
                      >
                        <input
                          className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                          type="password"
                        />
                      </Form.Item>
                    </div>
                    <div className="mt-4">
                      <label className="text-green fw-700 px-0">
                        Confirm Password
                      </label>
                      <Form.Item
                        name="password2"
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your pasword!",
                          },
                        ]}
                      >
                        <input
                          className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                          type="password"
                        />
                      </Form.Item>
                    </div>

                    <div className="mt-4">
                      <input
                        className="btn text-uppercase w-100 blue-gradient-bg-1 rounded-pill text-white fw-800 border-0 px-5"
                        type="submit"
                        value="Update"
                      />
                    </div>
                  </Form>
                </Spin>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default CorporateSideNavLiTags;
