import { useState, useEffect } from "react";
import { Spin, Form } from "antd";

import { getLocalStorage, Notifications, validatePhoneNumber } from "utils";

import { useAxios } from "hooks";

const SingleUpload = () => {
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [response, status, process] = useAxios("post");

  const refreshModal = () => {
    window.location.reload();
  };
  useEffect(() => {
    if (response) {
      try {
        if (response.status === 200) {
          setIsLoading(false);
          Notifications(
            "success",
            "Success",
            `Transaction sent for proccessing. Your transaction reference is ${response.data.message.response.vendor_reference}`
          );
          setTimeout(() => {
            refreshModal();
          }, 1500);
        } else {
          setIsLoading(false);
          setTimeout(() => {}, 1000);
          Notifications("error", "Error", response.data.message.response);
        }
      } catch (error) {
        Notifications("error", "Error", "Failed to process request");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const uploadSingleData = (values) => {
    try {
      setIsLoading(true);
      const headers = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorage("userInfo").loginToken}`,
        },
        mode: "cors",
      };

      const validatedPhoneNumber = validatePhoneNumber(values.phone_number);
      if (validatedPhoneNumber) {
        var singleUploadData = new FormData();
        singleUploadData.append("processing_type", "single");
        singleUploadData.append("currency", "zwl");
        singleUploadData.append("amount", values.amount);
        singleUploadData.append("transactionType", values.transactionType);
        singleUploadData.append("phone_number", values.phone_number);
        singleUploadData.append("transaction_type", "Airtime");

        process("transactions/post/", singleUploadData, headers);
      } else {
        Notifications("error", "Error", "Invalid phone number!!");
      }
    } catch (error) {
      setIsLoading(false);
      Notifications("error", "Error", "Failed to process request!!");
    }
  };

  return (
    <Spin
      tip="Please wait while we process your transaction....."
      spinning={isLoading}
    >
      <Form name="basic" onFinish={uploadSingleData} autoComplete="off">
        <div className="row">
          <div className="col-12 col-md-12 mt-4">
            <label className="text-green-2 fw-700 px-0">Select Biller</label>
            <Form.Item
              name="transactionType"
              rules={[{ required: true, message: "Select transaction type" }]}
            >
              <select
                class="form-select px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0 fs-12px"
                aria-label="Default select example"
              >
                <option selected value="">
                  Select{" "}
                </option>
                <option value="002">Netone Airtime</option>
                <option value="001">Econet Airtime</option>
                <option value="003">Telecel Airtime</option>
                <option disabled value="N.Richards">
                  N.Richards
                </option>
                <option disabled value="Gain">
                  Gain
                </option>
              </select>
            </Form.Item>
          </div>
          <div className="col-12 col-md-12 mt-4">
            <label className="text-green-2 fw-700 px-0">Phone Number</label>
            <Form.Item
              name="phone_number"
              rules={[{ required: true, message: "Enter phone number" }]}
            >
              <input
                class="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                type="text"
              />
            </Form.Item>
          </div>
          <div className="col-12 col-md-12 mt-4">
            <label className="text-green-2 fw-700 px-0">Amount </label>
            <Form.Item
              name="amount"
              rules={[{ required: true, message: "Enter amount!" }]}
            >
              <input
                class="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                type="number"
              />
            </Form.Item>
          </div>

          <div className="text-center mt-4">
            <input
              className="btn text-uppercase blue-gradient-bg-4 rounded-0 text-white fw-800 border-0 px-5"
              type="submit"
              value="submit"
            />
          </div>
        </div>
      </Form>
    </Spin>
  );
};

export default SingleUpload;
