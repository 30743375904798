import { AirtimePackage, DataPackage, VoucherPackage } from "components/bulk";
import { useParams } from "react-router-dom";

const BulkUpload = () => {
  const { bulkPackage } = useParams();

  return (
    <>
      {bulkPackage === "airtime" && <AirtimePackage />}
      {bulkPackage === "data" && <DataPackage />}
      {bulkPackage === "voucher" && <VoucherPackage />}
    </>
  );
};

export default BulkUpload;
